export const gsapAnimations = () => {
  gsap.registerPlugin(ScrollTrigger);

  if (document.querySelector('.animation')) {

    gsap.from('.home-hero-animation', {
      opacity: 0,
      scale: 1.2,
      duration: 1.6,
      ease: 'power1'
    })

    gsap.from('.page-hero-animation', {
      opacity: 0,
      scale: 1.2,
      duration: 1.6,
      ease: 'power1'
    })

    gsap.from('.text-pattern-animation', {
      x: -50,
      opacity: 0,
      duration: 0.5,
      ease: 'power1',
      scrollTrigger: {
        trigger: '#text-pattern',
        start: 'center bottom',
      }
    })

    gsap.from('.text-row-animation', {
      x: -50,
      opacity: 0,
      duration: 0.5,
      ease: 'power1',
      stagger: 0.3,
      scrollTrigger: {
        trigger: '#text-row',
        start: 'center bottom',
      }
    })

    gsap.from('.icon-text-animation', {
      x: -50,
      opacity: 0,
      duration: 0.5,
      ease: 'power1',
      stagger: 0.3,
      scrollTrigger: {
        trigger: '#icon-text',
        start: 'center bottom',
      }
    })

    gsap.from('.images-callout-animation', {
      x: -50,
      opacity: 0,
      duration: 0.5,
      ease: 'power1',
      scrollTrigger: {
        trigger: '#images-callout',
        start: 'center bottom',
      }
    })

    gsap.from('.text-vertical-images-animation', {
      x: -50,
      opacity: 0,
      duration: 0.5,
      ease: 'power1',
      scrollTrigger: {
        trigger: '#text-vertical-images',
        start: 'center bottom',
      }
    })

    gsap.from('.images-text-row-animation', {
      x: -50,
      opacity: 0,
      duration: 0.5,
      ease: 'power1',
      stagger: 0.3,
      scrollTrigger: {
        trigger: '#images-text-row',
        start: 'center bottom',
      }
    })

    gsap.from('.photos-animation', {
      x: -50,
      opacity: 0,
      duration: 0.5,
      ease: 'power1',
      stagger: 0.3,
      scrollTrigger: {
        trigger: '#photos',
        start: 'center bottom',
      }
    })
  }
}
